
.layout-container {
    background-color: var(--amplify-colors-background-secondary);
}

[data-amplify-authenticator] [data-amplify-container] { /* Not overlay with the header */
    margin-top: 10px;
}

.page-container {
    padding: 60px 30px 0px 290px;
    transition: padding 0.5s;
    min-height: calc(100vh - 80px);
}

.page-container-public {
    padding: 60px 0px 0px;
    transition: padding 0.5s;
    width: 100%;
    min-height: calc(100vh - 80px);
}


.card-title {
    font-weight: 600;
}

body {
    margin: 0;
    --header-height: 60px;
}

.header {
    background-color: #fff;
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100vw;
    height: var(--header-height);
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 0 1rem;
    flex-wrap: wrap;
}

.header-right {
    display: flex;
}

.nav-text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 820px;
    width: 100%;
}

.nav-text-container Text a {
    transition: box-shadow 0.3s ease-in-out;
    /* Smooth transition for the glow effect */
}

.nav-text-container Text a:hover {
    box-shadow: 0 0 18px rgba(255, 255, 255, 0);
    /* Example glow effect, adjust as needed */
    text-decoration: none;
    /* Optional: Removes underline on hover */
}

.amplify-menu-content {
    border-color: #fff;
}

.header-avatar {
    font-size: 30px;
    overflow: hidden;
}

.header-avatar img {
    font-size: 30px;
}

.github-avatar {

    font-size: 24px;
    overflow: hidden;
}

.avatar {
    font-size: 20px;
    overflow: hidden;
}

.header-logo {
    width: 30px;
    height: 30px;
}

.header-logo img {
    height: auto;
    width: 100%;
}

.header-left {
    display: flex;
    align-items: center;
}

.header-left span {
    margin-left: 11px;
    text-transform: uppercase;
}


.github-link .amplify-link {
    margin-top: 10px;
    margin-right: 10px;
    padding: 16px 8px;
    width: 34px;
    height: 34px;
    font-size: 24px;

}

.header-search-bar {
    padding: 7px 15px;
}

@media (max-width: 950px) {
    body {
        --header-height: 120px;
    }

    .header {
        display: grid;
        grid-template-columns: 110px 1fr 110px;
        justify-items: center;
        grid-template-areas:
                ". header-left header-right"
                "nav-text-container nav-text-container nav-text-container";
    }

    .header-left {
        grid-area: header-left;
    }

    .header-logo {
        width: 50px;
        height: 50px;
    }

    .header-left span {
        font-size: 30px;
    }

    .header-right {
        grid-area: header-right;
    }

    .header-right {
        grid-area: header-right;
    }

    .nav-text-container {
        grid-area: nav-text-container;
        padding-bottom: 10px;
    }

    .page-container {
        padding: var(--header-height) 30px 30px 20px;
    }
    .page-container-public {
        /* padding: var(--header-height) 0px 0px; */
        transition: padding 0.5s;
        overflow-x: hidden;
        min-height: calc(100vh - var(--header-height));
    }
}

@media (max-width: 550px) {
    body {
        --header-height: 90px;
    }

    .header {
        grid-template-columns: 55px 1fr 55px;
        padding: 0 5px;
    }

    .header-left {
        justify-content: space-around;
    }

    .header-logo {
        width: 30px;
        height: 30px;
    }

    .header-left span {
        font-size: 16px;
    }

    .nav-text-container {
        font-size: 0.8rem;
        justify-content: space-evenly;
    }

    .amplify-button {
        padding: 5px;
    }

    .header-avatar {
        font-size: 20px;
    }

    .github-avatar {
        font-size: 17px;
    }
}

